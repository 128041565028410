<template>
  <div ref="sectionRef" class="group relative">
    <div
      class="relative z-1 bg-opacity-90 py-10 transition-all duration-300 lg:py-24"
      :class="[
        data.variant === 'blue' && 'bg-blue-700 text-blue-100',
        data.variant === 'orange' && 'bg-orange-500 text-gray-950'
      ]"
    >
      <div class="grid-default container relative z-1">
        <div class="relative z-1 col-span-4 flex flex-col justify-center lg:col-span-7">
          <Heading v-if="data.title" semantic class="text-3xl font-light lg:pb-0 lg:text-9xl">
            {{ data.title }}
          </Heading>
          <div
            v-if="data.html"
            class="mt-3 text-base font-light lg:mt-10 lg:text-2xl"
            v-html="data.html"
          ></div>
        </div>
        <div v-if="data.buttons.length" class="relative z-1 col-span-2 flex items-center justify-end lg:col-span-5">
          <CircleButton
            iconName="arrow-right"
            :color="data.variant === 'blue' ? 'orange' : 'blue'"
            :size="isDesktop ? 'xl' : 'md'"
            :to="
              data.buttons[0].reference !== null
                ? localePathByType(
                    data.buttons[0].reference?.__typename as string,
                    data.buttons[0].reference?.slug as string
                  )
                : data.buttons[0].externalLink
            "
          />
        </div>
      </div>
    </div>
    <div
      v-if="isSectionVisible"
      class="animate-bg-slide absolute left-0 top-0 z-0 h-full w-full bg-[url(/assets/images/backgrounds/grain.webp)] bg-repeat object-center"
    ></div>
  </div>
</template>

<script setup lang="ts">
import type { SectionCtaRecord } from '~/types/generated'

const { isDesktop } = useDevice()
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<SectionCtaRecord>,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  sectionRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
    }
  },
  {
    threshold: 0
  }
)

onUnmounted(() => {
  stop()
})
</script>
